<template>
  <div class="entry-fields">
    <!-- <label for="">
        {{ currentPlayer.name }}
        <span v-if="isBippin">
          - Bip'n <span v-if="isBoppin"> AND Bop'n!</span></span
        ></label
      >
      <input type="number" size="3" inputmode="numeric" v-model="score" /> -->

    <div class="button-group">
      <h2>
        {{ currentPlayer.name }}
        <span class="take-status">{{ takeCount }}/{{ takeMax }}</span>
      </h2>
      <span>
        <button
          :disabled="!canTake"
          @click="updateScoreCard(playerId, sliderVal, true)"
          :class="isTake ? 'take-selected' : ''"
        >
          <font-awesome-icon :icon="['far', 'thumbs-up']" /></button
        >TAKE</span
      >
      <span>
        <button
          :disabled="!canPass"
          @click="updateScoreCard(playerId, sliderVal, false)"
          :class="isPass ? 'pass-selected' : ''"
        >
          <font-awesome-icon :icon="['far', 'thumbs-down']" /></button
        >PASS</span
      >
      <span>
        <button @click="updatePoley(playerId)">
          <font-awesome-icon icon="fa-regular fa-flag" /></button
        >POLEY</span
      >
    </div>
  </div>
</template>

<script>
import { useScoreCardStore } from "@/stores/scoreCardStore";
import { mapStores } from "pinia";

export default {
  name: "ScoreEntryRow",
  data() {
    return {
      score: "",
    };
  },
  props: {
    playerId: Number,
    sliderVal: String,
  },
  computed: {
    ...mapStores(useScoreCardStore),

    holesToPlay() {
      return (
        this.scoreCardStore.settings.gameSize -
        Object.keys(this.currentPlayer.score).length
      );
    },
    takeTotal() {
      return Object.keys(this.currentPlayer.takes).length;
    },
    currentPlayer() {
      return this.scoreCardStore.getPlayers[this.playerId];
    },
    canTake() {
      return Object.keys(this.currentPlayer.takes).length < this.takeMax;
    },
    canPass() {
      return this.takeMax - this.takeTotal < this.holesToPlay;
    },
    isTake() {
      if (this.currentPlayer.score[this.scoreCardStore.selectedHole]) {
        return (
          this.currentPlayer.score[this.scoreCardStore.selectedHole].isTake ==
          true
        );
      }
      return false;
    },
    isPass() {
      if (this.currentPlayer.score[this.scoreCardStore.selectedHole]) {
        return (
          this.currentPlayer.score[this.scoreCardStore.selectedHole].isTake ==
          false
        );
      }
      return false;
    },
    isBippin() {
      return (
        this.scoreCardStore.courseData.tees[this.scoreCardStore.selectedTees]
          .holeInfo[this.scoreCardStore.selectedHole - 1].handicap <=
        this.currentPlayer.hcp
      );
    },
    isBoppin() {
      return (
        this.scoreCardStore.courseData.tees[this.scoreCardStore.selectedTees]
          .holeInfo[this.scoreCardStore.selectedHole - 1].handicap <=
        this.currentPlayer.hcp - 18
      );
    },
    takeCount() {
      return Object.keys(this.currentPlayer.takes).length;
    },
    takeMax() {
      return (
        Math.floor(parseInt(this.scoreCardStore.settings.gameSize) / 2) + 1
      );
    },
  },
  methods: {
    updateScoreCard(index, value, isTake) {
      if (this.isBoppin) {
        this.scoreCardStore.updatePlayerScore(index, value, isTake, 2);
      } else if (this.isBippin) {
        this.scoreCardStore.updatePlayerScore(index, value, isTake, 1);
      } else {
        this.scoreCardStore.updatePlayerScore(index, value, isTake);
      }
    },
    updatePoley(id) {
      this.scoreCardStore.updatePoley(id);
    },
  },
};
</script>

<style scoped>
h2 {
  margin: 0;
  width: 40%;
}
.button-group {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  flex: 1 1 auto;
}

.button-group span {
  display: flex;
  flex-flow: column;
  text-align: center;
  font-size: 0.75em;
  width: 20%;
}
button {
  background: transparent;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 0.5em;
  border: none;
}
.take-selected {
  color: lime;
}
.pass-selected {
  color: red;
}
input {
  width: 3rem;
  text-align: center;
}
</style>
