<template>
  <tr>
    <th scope="scorecard-row" class="frozen">
      <div class="names">
        <span
          >{{ player.name }}
          <sup v-if="player.hcp">({{ player.hcp }})</sup></span
        >
        <span class="score-number">{{ takeScore }}</span>
      </div>
    </th>
    <td v-for="(item, index) in 9" :key="index">
      <score-box
        :player="player"
        :courseHcp="
          scoreCardStore.courseData.tees[scoreCardStore.selectedTees].holeInfo[
            index
          ].handicap
        "
        :coursePar="
          scoreCardStore.courseData.tees[scoreCardStore.selectedTees].holeInfo[
            index
          ].par
        "
        :holeIndex="index + 1"
      />
    </td>
    <td class="totals">
      <span v-html="frontNine" />
    </td>
    <td v-for="(item, index) in 9" :key="index">
      <score-box
        :player="player"
        :courseHcp="
          scoreCardStore.courseData.tees[scoreCardStore.selectedTees].holeInfo[
            index + 9
          ].handicap
        "
        :coursePar="
          scoreCardStore.courseData.tees[scoreCardStore.selectedTees].holeInfo[
            index + 9
          ].par
        "
        :holeIndex="index + 10"
      />
    </td>
    <td class="totals">
      <span v-html="backNine" />
    </td>
  </tr>
</template>
<script>
import scoreBox from "@/components/scoreBox.vue";
import { useScoreCardStore } from "@/stores/scoreCardStore";
import { mapStores } from "pinia";

export default {
  components: { scoreBox },
  name: "ScoreCardPlayerRow",
  async created() {},
  data() {
    return {};
  },
  props: {
    player: Object,
    playerId: String,
  },
  computed: {
    ...mapStores(useScoreCardStore),

    currentPlayer() {
      return this.scoreCardStore.getPlayers[this.playerId];
    },
    takeMax() {
      return (
        Math.floor(parseInt(this.scoreCardStore.settings.gameSize) / 2) + 1
      );
    },

    scoreTotal() {
      return "";
    },
    frontNine() {
      const fn = Object.entries(this.player.score).slice(0, 9);
      const gross = fn.reduce(
        (accumulator, currentValue) =>
          parseInt(accumulator) + parseInt(currentValue[1].gross),
        0
      );
      const net = fn.reduce(
        (accumulator, currentValue) =>
          parseInt(accumulator) + parseInt(currentValue[1].net),
        0
      );

      return `<sup>${gross}</sup>${net}`;
    },
    backNine() {
      const bn = Object.entries(this.player.score).slice(9, 18);
      const gross = bn.reduce(
        (accumulator, currentValue) =>
          parseInt(accumulator) + parseInt(currentValue[1].gross),
        0
      );
      const net = bn.reduce(
        (accumulator, currentValue) =>
          parseInt(accumulator) + parseInt(currentValue[1].net),
        0
      );
      return `<sup>${gross}</sup>${net}`;
    },
    takeScore() {
      return this.player.takesTotal;
    },
    takeCount() {
      return Object.keys(this.player.takes).length;
    },
  },
  methods: {},
};
</script>

<style scoped>
th {
  height: 40px;
}
.names {
  width: 50px;
  font-size: 0.55rem;
  text-align: center;
  padding: 0.15rem;
}
.totals {
  font-size: 0.75rem;
  font-weight: 700;
}
.score-number {
  display: block;
  font-size: 1rem;
}
.take-status {
  display: block;
  font-size: 0.5rem;
}
</style>
