<template>
  <div
    class="score-box hole-score"
    :class="[decor, isTaken]"
    @click="openScoreEntry"
  >
    <span v-if="isBippin" class="bip" /> <span v-if="isBoppin" class="bop" />
    <span v-if="isAdjusted">
      <sup>{{ grossScore }}</sup>
    </span>
    {{ netScore }}
  </div>
</template>

<script>
import { useScoreCardStore } from "@/stores/scoreCardStore";
import { mapStores } from "pinia";

export default {
  name: "scoreBox",
  async created() {},
  data() {
    return {};
  },
  props: {
    player: Object,
    courseHcp: Number,
    holeIndex: Number,
    coursePar: Number,
  },
  computed: {
    ...mapStores(useScoreCardStore),
    grossScore() {
      if (this.player.score[this.holeIndex]) {
        return this.player.score[this.holeIndex].gross;
      }
      return "";
    },
    netScore() {
      if (this.player.score[this.holeIndex]) {
        return this.player.score[this.holeIndex].net;
      }
      return "";
    },
    isBippin() {
      return this.courseHcp <= parseInt(this.player.hcp);
    },
    isBoppin() {
      return this.courseHcp <= parseInt(this.player.hcp - 18);
    },
    isAdjusted() {
      return this.grossScore != this.netScore;
    },
    isTaken() {
      if (this.player.score[this.holeIndex]) {
        if (this.player.score[this.holeIndex].isTake) return "is-take";
      }
      return "";
    },
    decor() {
      if (this.netScore) {
        if (this.netScore == this.coursePar - 1) {
          return "birdie";
        }
        if (this.netScore == this.coursePar + 1) {
          return "bogey";
        }
        if (this.netScore <= this.coursePar - 2) {
          return "eagle";
        }
        if (this.netScore >= this.coursePar + 2) {
          return "double";
        }
      }
      return "";
    },
  },
  methods: {
    openScoreEntry() {
      this.scoreCardStore.setShowScoreEntry(true);
      this.scoreCardStore.setSelectedHole(this.holeIndex);
      this.scoreCardStore.setSelectedPar(this.coursePar);
    },
  },
};
</script>

<style scoped>
.score-box {
  position: relative;
  outline: 1px dotted #0e67ba;
  width: 100%;
  height: 40px;
  cursor: pointer;
  background: linear-gradient(#04294c, #003360);
}

.bip,
.bop {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: yellowgreen;
  top: 2px;
  left: 2px;
}
.bop {
  left: 10px;
}
.hole-score {
  text-align: center;
  font-size: 1rem;
  color: #6084bd;
  font-weight: 700;
  line-height: 40px;
}
.placeholder {
  color: lightgray;
  font-weight: 400;
}
.is-take {
  opacity: 1;
  color: #d2e2fc;
}

.bogey:before,
.double:before,
.birdie:before,
.eagle:before {
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  position: absolute;
  border: 3px solid rgba(18, 156, 255, 0.838);
  width: 50%;
  height: 50%;
  background: transparent;
}
.double:before,
.eagle:before {
  border-style: double;
}
.birdie:before,
.eagle:before,
.eagle:after {
  border-radius: 50%;
}
sup {
  position: absolute;
  margin: -0.45rem;
  font-size: 0.5em;
  font-weight: 400;
}
</style>
