<template>
  <div id="app">
    <div class="header">
      <h1 @click="reconcile">
        <img
          src="./assets/favicon.svg"
          alt=""
          style="height: 25px; margin-right: 5px"
        />
        <em>deciball</em>
      </h1>
      <div>
        <h2>${{ kittie }}</h2>
      </div>
    </div>

    <transition name="fade">
      <div v-if="showRules">
        <info-panel />
      </div>
    </transition>
    <transition name="fade">
      <div class="scroll-card">
        <table cellpadding="0" cellspacing="0">
          <caption>
            {{
              scoreCardStore.courseData.name
            }}
          </caption>
          <tr class="parrow">
            <th class="frozen" style="background-color: black">Hole</th>
            <th v-for="(item, index) in 9" :key="index">{{ item }}</th>
            <th>OUT</th>
            <th v-for="(item, index) in 9" :key="index">{{ item + 9 }}</th>
            <th>IN</th>
            <th>TOTAL</th>
          </tr>
          <tr class="parrow">
            <th class="frozen">PAR</th>
            <th v-for="(item, index) in 9" :key="index">
              {{
                scoreCardStore.courseData.tees[scoreCardStore.selectedTees]
                  .holeInfo[index].par
              }}
            </th>
            <th></th>
            <th v-for="(item, index) in 9" :key="index">
              {{
                scoreCardStore.courseData.tees[scoreCardStore.selectedTees]
                  .holeInfo[index + 9].par
              }}
            </th>
            <th></th>
            <th></th>
          </tr>

          <!-- <tr>
            <th class="frozen">HCP</th>
            <th v-for="(item, index) in 9" :key="index">
              {{
                scoreCardStore.courseData.tees[scoreCardStore.selectedTees]
                  .holeInfo[index].handicap
              }}
            </th>
            <th></th>
            <th v-for="(item, index) in 9" :key="index">
              {{
                scoreCardStore.courseData.tees[scoreCardStore.selectedTees]
                  .holeInfo[index + 9].handicap
              }}
            </th>
            <th></th>
            <th></th>
          </tr>
  -->
          <score-card-player-row
            v-for="(player, index) in scoreCardStore.getPlayers"
            :key="player.name"
            :player="player"
            :playerId="index"
          />
        </table>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="showAddPlayer" class="add-player-form drawer">
        <button class="close" @click="showAddPlayer = !showAddPlayer">
          Close
        </button>
        <form @submit.prevent>
          <h1>ADD PLAYERS</h1>
          <label for="pname">PLAYER NAME</label>
          <input v-model="playerName" ref="nameInput" id="pname" type="text" />
          <label for="phcp">HCP</label>

          <input
            v-model="playerHcp"
            id="phcp"
            type="number"
            inputmode="numeric"
            size="3"
            maxlength="2"
          />
          <font-awesome-icon
            @click="addPlayer"
            :icon="['far', 'circle-check']"
            class="fin"
          />
        </form>
      </div>
    </transition>
    <score-entry-box :players="scoreCardStore.getPlayers" />
    <leader-board-poleys />
    <transition name="fade">
      <results-panel
        v-if="showResults"
        :results="results"
        @closeMe="showResults = false"
      />
    </transition>
    <transition name="fade">
      <settings-panel v-if="showSettings" @complete="closeAll" />
    </transition>
    <transition name="fade">
      <welcome-panel
        v-if="scoreCardStore.showWelcome"
        @clickShowRules="showRulesPanel()"
      />
    </transition>

    <div id="navbar">
      <div @click="showAddPlayerPanel()">
        <font-awesome-icon
          icon="fa-solid fa-user-plus"
          class="add-player-btn"
        />
      </div>
      <div @click="showRulesPanel()">
        <font-awesome-icon :icon="['fas', 'circle-info']" />
      </div>
      <div @click="showSettingsPanel()">
        <font-awesome-icon :icon="['fas', 'gear']" />
      </div>
      <div @click="reconcile">
        <font-awesome-icon :icon="['fas', 'crown']" />
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import newCourseData from "../public/data/oaksgc.json";
import ScoreEntryBox from "./components/ScoreEntryBox.vue";
import ScoreCardPlayerRow from "./components/ScoreCardPlayerRow.vue";
import LeaderBoardPoleys from "./components/LeaderBoardPoleys.vue";
import ResultsPanel from "./components/ResultsPanel.vue";
import { useScoreCardStore } from "./stores/scoreCardStore";
import { mapStores } from "pinia";
import InfoPanel from "./InfoPanel.vue";
import SettingsPanel from "./components/SettingsPanel.vue";
import WelcomePanel from "./components/WelcomePanel.vue";

export default {
  components: {
    ScoreCardPlayerRow,
    ScoreEntryBox,
    LeaderBoardPoleys,
    ResultsPanel,
    InfoPanel,
    SettingsPanel,
    WelcomePanel,
  },
  name: "App",
  async created() {
    // New JSON Format refactor
    this.scoreCardStore.setCourseData(newCourseData);
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      playerName: "",
      playerHcp: "",
      showAddPlayer: false,
      showRules: false,
      showSettings: false,
      results: [],
      sliderValue: 60,
      showResults: false,
    };
  },
  computed: {
    ...mapStores(useScoreCardStore),

    kittie() {
      return (
        this.scoreCardStore.settings.ante *
        this.scoreCardStore.currentPlayers.length
      );
    },
  },

  methods: {
    closeAll() {
      if (this.scoreCardStore.showScoreEntry == true) {
        this.scoreCardStore.setShowScoreEntry(false);
      }
      this.scoreCardStore.hideWelcome();
      this.showAddPlayer = false;
      this.showRules = false;
      this.showSettings = false;
    },
    showRulesPanel() {
      this.closeAll();
      this.showRules = true;
    },
    showSettingsPanel() {
      this.closeAll();
      this.showSettings = true;
    },
    showAddPlayerPanel() {
      this.closeAll();
      this.showAddPlayer = true;
    },
    addPlayer() {
      this.scoreCardStore.addPlayer({
        name: this.playerName.toUpperCase(),
        hcp: this.playerHcp,
        takes: {},
        score: {},
        poleysOwed: 0,
        poleysEarned: 0,
      });
      this.playerName = "";
      this.playerHcp = "";
    },
    reconcile() {
      this.closeAll();
      this.showResults = true;
      console.log("Time to settle up!");
      const rex = [];
      this.scoreCardStore.getPlayers.forEach((player) => {
        rex.push(player);
        player.poleysOwed = 0;
        player.poleysEarned = 0;
      });
      let winner = rex.sort((a, b) => a.takesTotal - b.takesTotal);
      this.scoreCardStore.calculatePoleys();
      this.results = winner;
    },
  },
};
</script>

<style>
:root {
  --primary-color: #0a3041;
  --on-primary-color: #efefef;
  --secondary-color: #3a4047;
}
.fin {
  margin-left: 0.5rem;
  font-size: 1.75rem;
}
html {
  position: fixed;
  width: 99vw;
  height: 100%;
  overflow: hidden;
  background-color: var(--primary-color);
}

body {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  color: var(--on-primary-color);
}

h1 {
  font-size: 20px;
  text-align: left;
}
h2 {
  font-size: 18px;
}
.header {
  display: flex;
  flex-direction: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.header h1 {
  margin: 0;
}
.header h1 em {
  display: inline-block;
  transform: translateY(-6px);
}
.scroll-card {
  width: 100vw;
  overflow: scroll;
}
table {
  width: 100%;
  margin-left: 50px;
  margin-right: 20px;
  border-collapse: collapse;
  border-spacing: 0px;
  padding: 0;
}
th {
  text-align: center;
  font-size: 0.75rem;
  min-width: 33px;
  font-weight: 100;
  padding: 0 0 0.1rem;
}
tr {
  position: relative;
}
.parrow th {
  font-size: 0.5rem;
}
td {
  position: relative;
  text-align: center;
  margin: 0;
  padding: 0;
}
caption {
  position: absolute;
  margin-left: 60px;
  margin-top: -2.25rem;
}

.frozen {
  text-align: left;
  position: fixed;
  z-index: 2;
  width: 55px;
  left: 0;
  background-color: var(--primary-color);
}

sup {
  font-size: 0.75em;
}

.bottom {
  display: flex;
}
input {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.5em;
  text-transform: uppercase;
  background-color: var(--primary-color);
  border-radius: 5px;
  border: 1px solid var(--on-primary-color);
  padding: 0.25em 0.5em;
  color: var(--on-primary-color);
}

#pname {
  width: 175px;
  margin-right: 1em;
}
#phcp {
  width: 1.5em;
}
label {
  font-size: 0.75em;
  display: inline-block;
  position: absolute;
  padding: 0 0.25em;
  margin-top: -0.5em;
  margin-left: 0.75em;
  background: var(--primary-color);
}
.close {
  position: absolute;
  left: 50%;
  top: 2px;
  background-color: darkgrey;
  font-size: 0rem;
  padding: 0;
  z-index: 10;
  border: none;
  width: 25%;
  height: 5px;
  border-radius: 5px;
  transform: translateX(-50%);
}
.drawer {
  background: var(--primary-color);
  margin: 5px;
  position: absolute;
  z-index: 2;
  left: 10px;
  right: 10px;
  bottom: 65px;
  padding: 15px;
  border-radius: 10px 10px 0 0;
  border: 1px solid gray;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.rs-full .rs-tooltip {
  top: -30px !important;
}
.rs-tooltip-text {
  font-family: "Roboto Condensed", sans-serif !important;
  font-weight: 700 !important;
  font-size: 36px !important;
}

#navbar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 65px;
  background-color: var(--secondary-color);
  border-top: 1px solid black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
}
.button {
  margin-bottom: 1rem;
  padding: 1rem;
  text-transform: uppercase;
  border-radius: 5px;
  font-family: "Roboto Condensed", sans-serif;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
}
.button__primary {
  background-color: var(--on-primary-color);
  color: var(--primary-color);
  border-color: var(--primary-color);
  font-weight: 700;
}
.button__secondary {
  background-color: var(--primary-color);
  color: var(--on-primary-color);
  border-color: var(--on-primary-color);
}
</style>
