<template>
  <div class="logo">
    <div>
      <svg
        class="arcs1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 85 235"
        preserveAspectRatio="none"
      >
        <ellipse class="cls-1" cx="28.6" cy="116.77" rx="27.6" ry="115.77" />
        <ellipse class="cls-1" cx="33.63" cy="116.77" rx="27.6" ry="115.77" />
        <ellipse class="cls-1" cx="38.67" cy="116.77" rx="27.6" ry="115.77" />
        <ellipse class="cls-1" cx="43.7" cy="116.77" rx="27.6" ry="115.77" />
        <ellipse class="cls-1" cx="48.73" cy="116.77" rx="27.6" ry="115.77" />
        <ellipse class="cls-1" cx="53.77" cy="116.77" rx="27.6" ry="115.77" />
      </svg>
    </div>
    <div>
      <svg
        class="arcs2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 85 235"
        preserveAspectRatio="none"
      >
        <ellipse class="cls-1" cx="28.6" cy="116.77" rx="27.6" ry="115.77" />
        <ellipse class="cls-1" cx="33.63" cy="116.77" rx="27.6" ry="115.77" />
        <ellipse class="cls-1" cx="38.67" cy="116.77" rx="27.6" ry="115.77" />
        <ellipse class="cls-1" cx="43.7" cy="116.77" rx="27.6" ry="115.77" />
        <ellipse class="cls-1" cx="48.73" cy="116.77" rx="27.6" ry="115.77" />
        <ellipse class="cls-1" cx="53.77" cy="116.77" rx="27.6" ry="115.77" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandingLogo",
  async created() {},
  data() {
    return {};
  },
  props: {
    w: {
      type: Number,
      default: 100,
    },
  },
  computed: {
    wid() {
      return `width: ${this.w}`;
    },
  },
  methods: {},
};
</script>

<style scoped>
.logo {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.arcs1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 125px;
  height: 300px;
  animation: rolling 10s linear infinite alternate;
}

.arcs2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 125px;
  height: 300px;
  animation: rolling 10s linear infinite alternate;
}
@keyframes rolling {
  from {
    width: 1px;
  }
  to {
    width: 305px;
  }
}

.cls-1 {
  fill: none;
  stroke: var(--on-primary-color);
  stroke-width: 1.75;
  stroke-miterlimit: 10;
  vector-effect: non-scaling-stroke;
  animation: rolling 10s linear infinite alternate;
}
</style>
