<template>
  <div class="info-panel">
    <h1>How to play</h1>
    Get ready for an exciting twist on traditional golf! We've spiced up the
    round with a fun game called <strong>"DECIBALL."</strong> Here's how it
    works:
    <ol>
      <li>We'll play using standard stroke play rules, including handicaps.</li>
      <li>
        But wait, there's a juicy decision to make after each hole! You can
        either give it a giant thumbs-up
        <font-awesome-icon :icon="['far', 'thumbs-up']" /> or dismiss it with a
        disdainful thumbs-down
        <font-awesome-icon :icon="['far', 'thumbs-down']" /> on your score.
      </li>
      <li>
        Oh, but don't forget, you must make this life-altering choice before
        teeing off for the next hole. The pressure is real, folks!
      </li>
      <li>
        A full game consists of ten scores if we're playing 18 holes or five
        scores if we're playing 9 holes.
      </li>
      <li>
        Now here's where it gets feisty: if your scorecard has fewer decisions
        than there are holes left, brace yourself for some "tough luck" moments.
        You'll be forced to face the music and take those remaining scores
        without the option to pass. Ouch!
      </li>
    </ol>
    <p>
      With Deciball, every hole becomes a thrilling opportunity to strategize
      and make the right choice. Will you give a thumbs-up and trust your skills
      or play it safe with a thumbs-down? The decision is yours!
    </p>
    <p>
      Get ready to embark on a golfing adventure like no other, where every
      swing counts and every decision matters. Let's tee off and see who emerges
      as the ultimate champion of Deciball!
    </p>

    <p>
      <strong>Introducing "Poleys"</strong> - the thrilling game within the game
      that takes your golfing experience to new heights of fun!—Are you ready?
    </p>
    <p>
      If you hole out from off the green or sink your FIRST putt from a distance
      longer than the flagstick, you earn a coveted "poley."
    </p>

    <p><em>Now, how you score poleys is up to you:</em></p>

    <p>
      <strong>Variation 1:</strong> Point-a-Palooza - Each poley is worth
      escalating points. The first earns you "1pt," the second "2pt," and so on.
      Collect points and revel in your poley prowess!
    </p>

    <p>
      <strong>Variation 2:</strong> Poley Pressure - With a poley, you gain the
      power to force all other players to take the NEXT hole score without the
      option to pass. Get ready to stir up some intense competition!
    </p>

    <p>
      Poleys add a thrilling twist to your golf game, rewarding exceptional
      shots and strategic gameplay. Will you chase after poleys to stack up
      points? Or use your poley power to put pressure on your opponents?
    </p>
  </div>
</template>

<script>
import { useScoreCardStore } from "@/stores/scoreCardStore";
import { mapStores } from "pinia";

export default {
  name: "InfoPanel",
  async created() {},
  data() {
    return {};
  },
  props: {},
  computed: {
    ...mapStores(useScoreCardStore),
  },
  methods: {},
};
</script>

<style scoped>
.info-panel {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  padding: 0px 10px 60px;
  z-index: 9;
  background-color: var(--primary-color);
  line-height: 1.4;
  overflow-y: auto;
  font-size: 1.2rem;
}
ol {
  padding-left: 1.5rem;
}
li {
  margin-bottom: 0.5rem;
}
</style>
