<template>
  <div class="screen">
    <div class="logo">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 188.1 188.1"
        style="enable-background: new 0 0 188.1 188.1"
        xml:space="preserve"
      >
        <g>
          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -47.8629 90.3847)"
            class="st0"
            cx="85.2"
            cy="103"
            rx="27.6"
            ry="115.8"
          />

          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -44.3038 91.8589)"
            class="st0"
            cx="88.7"
            cy="99.4"
            rx="27.6"
            ry="115.8"
          />

          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -40.7447 93.3331)"
            class="st0"
            cx="92.3"
            cy="95.8"
            rx="27.6"
            ry="115.8"
          />

          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -37.1856 94.8073)"
            class="st0"
            cx="95.8"
            cy="92.3"
            rx="27.6"
            ry="115.8"
          />

          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -33.6265 96.2816)"
            class="st0"
            cx="99.4"
            cy="88.7"
            rx="27.6"
            ry="115.8"
          />

          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -30.0674 97.7558)"
            class="st0"
            cx="103"
            cy="85.2"
            rx="27.6"
            ry="115.8"
          />

          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -35.2796 85.1725)"
            class="st0"
            cx="85.2"
            cy="85.2"
            rx="115.8"
            ry="27.6"
          />

          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -36.7538 88.7316)"
            class="st0"
            cx="88.7"
            cy="88.7"
            rx="115.8"
            ry="27.6"
          />

          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -38.2281 92.2907)"
            class="st0"
            cx="92.3"
            cy="92.3"
            rx="115.8"
            ry="27.6"
          />

          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -39.7023 95.8498)"
            class="st0"
            cx="95.8"
            cy="95.8"
            rx="115.8"
            ry="27.6"
          />

          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -41.1765 99.4089)"
            class="st0"
            cx="99.4"
            cy="99.4"
            rx="115.8"
            ry="27.6"
          />
          <ellipse
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -42.6507 102.968)"
            class="st0"
            cx="103"
            cy="103"
            rx="115.8"
            ry="27.6"
          />
        </g>
      </svg>
    </div>

    <h1><em>deciball</em></h1>
    <p>
      <em
        >Things are about<br />to get <strong>ten times</strong><br />more
        interesting!</em
      >
    </p>
    <button
      class="button button__primary"
      @click="scoreCardStore.hideWelcome()"
    >
      LET'S DO THIS!
    </button>
    <button class="button button__secondary" @click="$emit('clickShowRules')">
      how to play
    </button>
  </div>
</template>

<script>
import { useScoreCardStore } from "@/stores/scoreCardStore";
import { mapStores } from "pinia";

export default {
  name: "WelcomePanel",
  async created() {},
  data() {
    return {};
  },
  props: {},
  computed: {
    ...mapStores(useScoreCardStore),
  },
  methods: {},
};
</script>

<style scoped>
.screen {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  padding: 0px 10px 60px;
  z-index: 9;
  background-color: var(--primary-color);
  line-height: 1.4;
  font-size: 2.25rem;
  text-align: center;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 72px;
}
</style>
<style>
.st0 {
  fill: none;
  stroke: var(--on-primary-color);
  stroke-width: 2.5;
  stroke-miterlimit: 10;
}
</style>
