<template>
  <div class="poleys">
    <div class="badge" v-for="(item, index) in poleys" :key="index">
      <img src="../assets/poley2.svg" alt="Poley Badge" />
      <span class="details"
        >{{ item.name }}<br />
        Hole {{ item.hole }}</span
      >
    </div>
  </div>
</template>

<script>
import { useScoreCardStore } from "@/stores/scoreCardStore";
import { mapStores } from "pinia";

export default {
  name: "LeaderBoardPoleys",
  async created() {},
  data() {
    return {};
  },
  props: {},
  computed: {
    ...mapStores(useScoreCardStore),
    poleys() {
      return this.scoreCardStore.getPoleys;
    },
  },
  methods: {},
};
</script>

<style scoped>
.poleys {
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
}
.badge {
  position: relative;
}
img {
  height: 80px;
  margin: 2px;
  z-index: 1;
}
.details {
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: 10px;
  font-size: 0.65em;
  font-weight: 700;
  color: white;
  text-align: center;
  line-height: 30px;
  transform: translateX(-50%);
}
.details:first-line {
  color: black;
}
</style>
