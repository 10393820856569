<template>
  <transition name="fade">
    <div v-if="showScoreEntry" class="drawer" id="score-entry">
      <button class="close" @click="scoreCardStore.setShowScoreEntry(false)">
        close
      </button>
      <h1>
        <button @click="prevHole" :disabled="hasPrev">
          <font-awesome-icon icon="fa-solid fa-chevron-left" />
        </button>
        <span class="lable">HOLE {{ scoreCardStore.selectedHole }}</span>
        <span class="lable"> PAR {{ scoreCardStore.selectedPar }} </span>
        <button @click="nextHole" :disabled="hasNext">
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </button>
      </h1>
      <div class="arc-slider">
        <round-slider
          radius="350"
          start-angle="65"
          end-angle="+50"
          line-cap="round"
          v-model="sliderValue"
          max="9"
          min="1"
          handle-size="+20"
          show-tooltip="true"
        />
      </div>
      <div
        class="entries"
        v-for="(player, index) in scoreCardStore.getPlayers"
        :key="index"
      >
        <score-entry-row :playerId="index" :sliderVal="sliderValue" />
      </div>
    </div>
  </transition>
</template>

<script>
import { useScoreCardStore } from "@/stores/scoreCardStore";
import { mapStores } from "pinia";
import ScoreEntryRow from "./ScoreEntryRow.vue";
import RoundSlider from "vue-three-round-slider";

export default {
  components: { ScoreEntryRow, RoundSlider },
  name: "ScoreEntryBox",
  data() {
    return {
      sliderValue: 5,
    };
  },
  props: {},
  computed: {
    ...mapStores(useScoreCardStore),
    showScoreEntry() {
      return this.scoreCardStore.getShowScoreEntry;
    },
    hasPrev() {
      return this.scoreCardStore.selectedHole == 1;
    },
    hasNext() {
      return this.scoreCardStore.selectedHole == 18;
    },
  },

  methods: {
    nextHole() {
      this.scoreCardStore.setSelectedHole(this.scoreCardStore.selectedHole + 1);
      this.scoreCardStore.setSelectedPar(
        this.scoreCardStore.courseData.tees[this.scoreCardStore.selectedTees]
          .holeInfo[this.scoreCardStore.selectedHole - 1].par
      );
    },
    prevHole() {
      this.scoreCardStore.setSelectedHole(this.scoreCardStore.selectedHole - 1);
      this.scoreCardStore.setSelectedPar(
        this.scoreCardStore.courseData.tees[this.scoreCardStore.selectedTees]
          .holeInfo[this.scoreCardStore.selectedHole - 1].par
      );
    },
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 7rem;
  margin-block-start: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.75rem;
}

.arc-slider {
  position: absolute;
  left: 50%;
  top: 85px;
  transform: translateX(-50%);
  z-index: 0;
}
.entries {
  z-index: 2;
  position: relative;
  margin-bottom: 0.75rem;
}
.entries:last-child {
  margin-bottom: 0;
}
.lable {
  font-size: 1.25rem;
}
</style>
