import { defineStore } from "pinia";

export const useScoreCardStore = defineStore("scoreCard", {
  id: "scoreCard",
  state: () => ({
    settings: {
      ante: 20,
      pressure: false,
      gameSize: 18,
      poleyValue: 1,
    },
    courseData: {},
    selectedTees: 0,
    selectedHole: 0,
    selectedPar: 0,
    currentPlayers: [],
    poleys: [],
    showScoreEntry: false,
    showWelcome: true,
  }),
  getters: {
    getSelectedHole(state) {
      return state.selectedHole;
    },
    getSelectedPar(state) {
      return state.selectedPar;
    },
    getPlayers(state) {
      return state.currentPlayers;
    },
    getPoleys(state) {
      return state.poleys;
    },
    getShowScoreEntry(state) {
      return state.showScoreEntry;
    },
  },
  actions: {
    hideWelcome() {
      this.showWelcome = false;
    },

    setSelectedTees(newTees) {
      this.selectedTees = newTees;
    },
    setCourseData(courseJSON) {
      this.courseData = courseJSON;
    },
    setShowScoreEntry(isShowScore) {
      this.showScoreEntry = isShowScore;
    },
    setSelectedHole(newHole) {
      this.selectedHole = newHole;
    },
    setSelectedPar(newPar) {
      this.selectedPar = newPar;
    },
    addPlayer(player) {
      this.currentPlayers.push(player);
      this.kittie = this.settings.ante * this.currentPlayers.length;
    },
    updatePlayerScore(index, score, take, mod = 0) {
      console.log("updatePlayerScore:" + [index, score, take, mod]);
      const player = this.currentPlayers[index];
      const grossScore = score;
      const netScore = score - mod;
      player.score[this.selectedHole] = {
        gross: grossScore,
        net: netScore,
        isTake: take,
      };
      if (take == true && Object.keys(player.takes).length < 10) {
        player.takes[this.selectedHole] = netScore - this.selectedPar;
      } else {
        console.log(`${player.name} passes.`);
        if (player.takes[this.selectedHole] != undefined) {
          console.log(
            `${player.name} passes but already has an entry for hole ${this.selectedHole}`
          );
          player.score[this.selectedHole].isTake = false;
          console.log(player.takes);
          delete player.takes[this.selectedHole];
          console.log(`takes array has ${player.takes.length} items`);
        }
      }
      const takeScore = Object.values(player.takes).reduce(
        (accumulator, currentValue) =>
          parseInt(accumulator) + parseInt(currentValue),
        0
      );

      player.takesTotal = takeScore;
    },
    updatePoley(index) {
      let obj = {
        name: this.currentPlayers[index].name,
        hole: this.selectedHole,
        index: index,
      };
      let hasDupes = this.poleys.find(
        (winner) => winner["name"] === obj.name && winner["hole"] === obj.hole
      );

      if (!hasDupes) {
        this.poleys.push(obj);
      } else {
        this.poleys.splice(
          this.poleys.findIndex(
            (el) => el.name == obj.name && el.hole == obj.hole
          ),
          1
        );
      }
    },
    calculatePoleys() {
      this.poleys.forEach((poley, index) => {
        this.updatePoleyPayments(poley, index);
      });
    },
    updatePoleyPayments(poley, value) {
      this.currentPlayers.forEach((player) => {
        if (player.name != poley.name) {
          player.poleysOwed += value + 1;
        } else {
          console.log("HI");
          let a = this.currentPlayers.length - 1;
          let b = value + 1;
          player.poleysEarned += a * b;
        }
      });
    },
    updateSettings(newSettings) {
      this.settings = newSettings;
    },
  },
});
