<template>
  <div id="game-settings">
    <h1>GAME SETTINGS</h1>
    <label for="anteup">BUY IN</label>
    <input type="text" id="anteup" size="4" v-model="ante" />
    <label for="poleyVal">POLEY VALUE</label>
    <input
      type="text"
      name="poleyVal"
      id="poleyVal"
      size="4"
      v-model="poleyValue"
    />
    <fieldset>
      <legend>NUMBER OF HOLES</legend>

      <input
        type="radio"
        name="gameSize"
        id="gameSize9"
        value="9"
        v-model="gameSize"
      />
      <label class="teelabel" for="gameSize9">9</label>

      <input
        type="radio"
        name="gameSize"
        id="gameSize18"
        value="18"
        v-model="gameSize"
      />
      <label class="teelabel" for="gameSize18">18</label>
    </fieldset>
    <fieldset>
      <legend>SELECT COURSE</legend>

      <select
        name="courseSelect"
        id="courseSelect"
        v-model="courseSelect"
        @change="loadCourseData()"
      >
        <option value="baraboocc">Baraboo C.C.</option>
        <option value="bishopsbaycc">Bishops Bay C.C.</option>
        <option value="bridges">The Bridges Golf Course</option>

        <option value="doorcreekgc">Door Creek Golf Course</option>
        <option value="laclabellecc">Lac Labelle C.C.</option>
        <option value="lakewindsorcc">Lake Windsor C.C.</option>
        <option value="lawsonialinkscourse">Lawsonia (Links)</option>
        <option value="lawsoniawoodlands">Lawsonia (Woodlands)</option>
        <option value="maplebluffcc">Maple Bluff C.C.</option>
        <option value="mononagc">Monona Golf Course</option>
        <option value="nagawaukeegc">Nagawaukee Golf Course</option>
        <option value="oaksgc">The Oaks Golf Club</option>
        <option value="oldhickorygc">Old Hickory Golf Course</option>
      </select>
      <div
        class="teeboxes"
        v-for="(tee, index) in scoreCardStore.courseData.tees"
        :key="index"
      >
        <input
          type="radio"
          :id="`tee${index}`"
          :value="index"
          v-model="teeBoxSelect"
          name="teeSelect"
        />
        <label class="teelabel" :for="`tee${index}`"
          >{{ tee.name }} / {{ tee.type }}</label
        >
      </div>
    </fieldset>
    <button class="button button__primary" @click="confirmSettings">
      start game
    </button>
    <br />
    <button class="button button__secondary" @click="resetGame">
      RESET GAME
    </button>
  </div>
</template>

<script>
import { useScoreCardStore } from "@/stores/scoreCardStore";
import { mapStores } from "pinia";

export default {
  name: "SettingsPanel",
  async created() {},
  data() {
    return {
      ante: 20,
      gameSize: 18,
      poleyValue: 1,
      teeBoxSelect: 0,
      courseSelect: "oaksgc",
    };
  },
  props: {},
  computed: {
    ...mapStores(useScoreCardStore),
  },
  methods: {
    // load a new file from local subdirectory
    async loadCourseData() {
      const response = await fetch(
        //`http://localhost:8080/data/${this.courseSelect}.json`
        `https://golf.thegorskis.com/data/${this.courseSelect}.json`
      );
      const data = await response.json();
      this.scoreCardStore.setCourseData(data);
    },
    confirmSettings() {
      this.scoreCardStore.updateSettings({
        ante: this.ante,
        gameSize: this.gameSize,
        poleyValue: this.poleyValue,
      });
      this.scoreCardStore.setSelectedTees(this.teeBoxSelect);
      // emit an event to the parent to close the settings panel
      this.$emit("complete");
    },
    resetGame() {
      location.reload();
    },
  },
  components: {},
};
</script>

<style scoped>
#game-settings {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-color);
  z-index: 2;
  padding: 20px;
}
#anteup {
  margin-bottom: 1rem;
  margin-right: 1rem;
}
legend {
  font-size: 0.75rem;
}
fieldset {
  border-radius: 5px;
  margin-bottom: 1rem;
}
select {
  margin-bottom: 1rem;
  font-family: "Roboto Condensed", sans-serif;
}
.teelabel {
  position: relative;
  margin-right: 2rem;
}
</style>
