import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faUserPlus,
  faCircleInfo,
  faChevronLeft,
  faChevronRight,
  faCrown,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFlag,
  faCircleCheck,
  faCircleXmark,
  faThumbsDown,
  faThumbsUp,
  faMoneyBill1,
} from "@fortawesome/free-regular-svg-icons";

/* add icons to the library */
library.add([
  faUserPlus,
  faFlag,
  faCircleCheck,
  faCircleXmark,
  faCircleInfo,
  faThumbsDown,
  faThumbsUp,
  faChevronLeft,
  faChevronRight,
  faCrown,
  faMoneyBill1,
  faGear,
]);
const app = createApp(App);
// following instructions https://docs.amplify.aws/start/getting-started/setup/q/integration/vue/#install-amplify-libraries
//import { Amplify } from "aws-amplify";
//import awsExports from "./aws-exports";
//Amplify.configure(awsExports);

app.use(createPinia());
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
