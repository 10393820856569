<template>
  <div class="results-panel">
    <h1>RESULTS</h1>
    <div class="winner">
      <branding-logo :w="350" />
      <p class="winner-name">
        {{ winner.name }}
      </p>
      <p class="winner-score">{{ winner.takesTotal }}</p>
    </div>

    <table>
      <tr>
        <th>Player</th>
        <th>Score</th>
        <th>Poleys won</th>
        <th>Poleys lost</th>
        <th>Pot owed</th>
        <th>Pot earned</th>
        <th>Payout</th>
      </tr>

      <tr>
        <td>{{ results[0].name }}</td>
        <td>{{ results[0].takesTotal }}</td>
        <td>{{ results[0].poleysEarned }}</td>
        <td>{{ results[0].poleysOwed }}</td>
        <td>{{ scoreCardStore.settings.ante }}</td>
        <td>{{ payoutStructure(0) }}</td>
        <td>
          {{ kittieDelta(0) + poleyDelta(results[0]) }}
        </td>
      </tr>

      <tr v-if="results.length > 1">
        <td>{{ results[1].name }}</td>
        <td>{{ results[1].takesTotal }}</td>
        <td>{{ results[1].poleysEarned }}</td>
        <td>{{ results[1].poleysOwed }}</td>
        <td>{{ scoreCardStore.settings.ante }}</td>
        <td>{{ payoutStructure(1) }}</td>
        <td>
          {{ kittieDelta(1) + poleyDelta(results[1]) }}
        </td>
      </tr>
      <tr v-if="results.length > 2">
        <td>{{ results[2].name }}</td>
        <td>{{ results[2].takesTotal }}</td>
        <td>{{ results[2].poleysEarned }}</td>
        <td>{{ results[2].poleysOwed }}</td>
        <td>{{ scoreCardStore.settings.ante }}</td>
        <td>{{ payoutStructure(2) }}</td>
        <td>
          {{ kittieDelta(2) + poleyDelta(results[2]) }}
        </td>
      </tr>
      <tr v-if="results.length > 3">
        <td>{{ results[3].name }}</td>
        <td>{{ results[3].takesTotal }}</td>
        <td>{{ results[3].poleysEarned }}</td>
        <td>{{ results[3].poleysOwed }}</td>
        <td>{{ scoreCardStore.settings.ante }}</td>
        <td>{{ payoutStructure(3) }}</td>
        <td>
          {{ kittieDelta(3) + poleyDelta(results[3]) }}
        </td>
      </tr>
    </table>
    <ol></ol>
    <button @click="$emit('closeMe')">close</button>
  </div>
</template>

<script>
import BrandingLogo from "@/components/BrandingLogo";
import { useScoreCardStore } from "@/stores/scoreCardStore";
import { mapStores } from "pinia";

export default {
  components: {
    BrandingLogo,
  },
  name: "ResultsPanel",
  async created() {},
  data() {
    return {};
  },
  props: {
    results: Array,
  },
  computed: {
    ...mapStores(useScoreCardStore),
    poleys() {
      return this.scoreCardStore.getPoleys;
    },
    kittie() {
      return parseInt(this.scoreCardStore.settings.ante) * this.results.length;
    },
    winner() {
      return this.results[0];
    },
  },
  methods: {
    payoutStructure(place) {
      switch (place) {
        case 0:
          if (this.results.length === 4) {
            return this.kittie * 0.75;
          } else if (this.results.length === 3) {
            return this.kittie * 0.8;
          } else {
            return this.kittie;
          }

        case 1:
          if (this.results === 4) {
            return this.kittie * 0.2;
          } else if (this.results.length === 3) {
            return this.kittie * 0.15;
          } else {
            return this.kittie * 0;
          }

        case 2:
          if (this.results.length === 4) {
            return this.kittie * 0.05;
          } else {
            return this.kittie * 0;
          }
        case 3:
          return this.kittie * 0.0;
      }

      return;
    },
    kittieDelta(place) {
      switch (place) {
        case 0:
          if (this.results.length === 4) {
            return this.kittie * 0.75 - this.scoreCardStore.settings.ante;
          } else if (this.results.length === 3) {
            return this.kittie * 0.8 - this.scoreCardStore.settings.ante;
          } else {
            return this.kittie - this.scoreCardStore.settings.ante;
          }

        case 1:
          if (this.results === 4) {
            return this.kittie * 0.2 - this.scoreCardStore.settings.ante;
          } else if (this.results.length === 3) {
            return this.kittie * 0.15 - this.scoreCardStore.settings.ante;
          } else {
            return this.kittie * 0 - this.scoreCardStore.settings.ante;
          }

        case 2:
          if (this.results.length === 4) {
            return this.kittie * 0.05 - this.scoreCardStore.settings.ante;
          } else {
            return this.kittie * 0 - this.scoreCardStore.settings.ante;
          }
        case 3:
          return this.kittie * 0.0 - this.scoreCardStore.settings.ante;
      }

      return;
    },
    poleyDelta(player) {
      return player.poleysEarned - player.poleysOwed;
    },
  },
};
</script>

<style scoped>
table {
  margin-left: 0;
}
.results-panel {
  position: absolute;
  z-index: 3;
  background: var(--primary-color);
  top: 60px;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 10px;
}
.winner {
  border-radius: 10px;
  width: 95vw;
  height: 70vw;
  text-align: center;
  background: linear-gradient(to bottom, #37acff, #0066ff);
  position: relative;
  overflow: hidden;
}
.winner-name {
  font-size: 6rem;
  font-weight: 700;
  background: linear-gradient(to bottom, #00eaff, #0084ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 3px var(--on-primary-color);
  filter: drop-shadow(0px 10px 20px var(--primary-color));
  margin-top: 0;
  padding-top: 6rem;
  z-index: 2;
  position: relative;
}
.winner-score {
  font-size: 4rem;
  font-weight: 700;
  background: linear-gradient(to bottom, #00eaff, #0084ff);
  filter: drop-shadow(0px 10px 20px var(--primary-color));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 3px var(--on-primary-color);
  z-index: 2;
  margin-top: -7rem;
  position: relative;
}
.crown {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 50%;
  top: 44%;
  left: 49.5%;
  filter: drop-shadow(0px 5px 10px #362008);
}
.st0 {
  fill: url(#SVGID_1_);
}
.st1 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 7;
  stroke-miterlimit: 10;
}
.crown-gradient-bottom {
  stop-color: #ffcd81;
}
.crown-gradient-top {
  stop-color: #fff5e3df;
}
</style>
